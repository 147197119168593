/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    h4: "h4",
    pre: "pre",
    code: "code",
    p: "p",
    strong: "strong",
    hr: "hr",
    h5: "h5",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Importing and Exporting React Component Reference"), "\n", React.createElement(_components.h3, null, "Default Import Export"), "\n", React.createElement(_components.h4, null, "Solution One"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Foo.js"
  }, "const Foo = () => {\n    return (<div>Foo</div>)\n}\n\nexport default Foo\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "or")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Foo.js"
  }, "function Foo(){\n    return (<div>Foo</div>)\n}\n\nexport default Foo\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=App.js"
  }, "import Foo from './Components/Foo'\n")), "\n", React.createElement(_components.h4, null, "Solution Two"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Foo.js"
  }, "export default Foo = () => {\n    return (<div>Foo</div>)\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "or")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Foo.js"
  }, "export default function Foo(){\n    return (<div>Foo</div>)\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=App.js"
  }, "import Foo from './Components/Foo'\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, null, "Named Export"), "\n", React.createElement(_components.h4, null, "Single Component"), "\n", React.createElement(_components.h5, null, "Solution One"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Foo.js"
  }, "const Foo = () => {\n    return (<div>Foo</div>)\n}\n\nexport { Foo }\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "or")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Foo.js"
  }, "function Foo() {\n    return (<div>Foo</div>)\n}\n\nexport { Foo }\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=App.js"
  }, "import {Foo} from './Components/Foo'\n")), "\n", React.createElement(_components.h5, null, "Option Two"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Foo.js"
  }, "export const Foo = () => {\n    return (<div>Foo</div>)\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "or")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Foo.js"
  }, "export function Foo(){\n    return (<div>Foo</div>)\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=App.js"
  }, "import {Foo} from './Components/Foo'\n")), "\n", React.createElement(_components.h4, null, "Export/Import Multiple Components From Single File"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Elements.js"
  }, "const Foo = () => {\n    return (<div>Foo</div>)\n}\n\nconst Bar = () => {\n    return (<div>Bar</div>)\n}\n\nexport { Foo, Bar }\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "or")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Elements.js"
  }, "function Foo() {\n    return (<div>Foo</div>)\n}\n\nfunction Bar() {\n    return (<div>Foo</div>)\n}\n\nexport { Foo, Bar }\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=App.js"
  }, "import { Foo, Bar } from './Components/Elements'\n")), "\n", React.createElement(_components.h5, null, "Option Two"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Elements.js"
  }, "export const Foo = () => {\n    return (<div>Foo</div>)\n}\n\nexport const Bar = () => {\n    return (<div>Foo</div>)\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "or")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Foo.js"
  }, "export function Foo(){\n    return (<div>Foo</div>)\n}\n\nexport function Bar(){\n    return (<div>Bar</div>)\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=App.js"
  }, "import { Foo, Bar } from './Components/Elements'\n")), "\n", React.createElement(_components.h3, null, "Keeping Apps Organized By Using A Component Staging File"), "\n", React.createElement(_components.p, null, "Using a component staging file can make managment of apps with many components simpler."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Benefits of a component staging file")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "If we refactor a components file name ", React.createElement(_components.strong, null, "or"), " path we only need to change the path name in a single file"), "\n", React.createElement(_components.li, null, "Reduces the number of import statements"), "\n"), "\n", React.createElement(_components.h4, null, "Handling Default Exports in Staging File"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Simple Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Atoms/Foo.js"
  }, "export default Foo = () => {\n    return (<div>Foo</div>)\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/index.js"
  }, "export { default as Foo } from './Atoms/Foo'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=App.js"
  }, "import {Foo} from './Components'\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Exmaple - One")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Atoms/Foo.js"
  }, "export default Foo = () => {\n    return (<div>Foo</div>)\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Atoms/Bar.js"
  }, "export default Bar = () => {\n    return (<div>Bar</div>)\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/Atoms/Elements.js"
  }, "export const ElemntOne = () => {\n    return (<div>ElementOne</div>)\n}\n\nexport const ElemntTwo = () => {\n    return (<div>ElementOne</div>)\n}\n\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=Components/index.js"
  }, "export { default as Foo } from './Atoms/Foo'\n\nexport { ElemntOne, ElementTwo } from './Elements'\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=App.js"
  }, "import {\n    Foo,\n    Bar,\n    ElemntOne,\n    ElementTwo\n} from './Components'\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
